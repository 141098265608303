import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2579272825/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "users"
    }}>{`Users`}</h1>
    <hr />
    <h2 {...{
      "id": "fetchusers"
    }}>{`fetchUsers`}</h2>
    <h3 {...{
      "id": "params"
    }}>{`Params`}</h3>
    <p>{`No specific params.`}</p>
    <h3 {...{
      "id": "resolves"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* users -> User[] = an array of users
* count -> number = number of users matching
* nextPageToken -> string = page token if there are matches beyond the limit
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { fetchUsers } from '@kineticdata/react';

fetchUsers().then(({ users }) => console.log(users));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "fetchuser"
    }}>{`fetchUser`}</h2>
    <h3 {...{
      "id": "params-1"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* username -> string = (required) username of the user
`}</code></pre>
    <h3 {...{
      "id": "resolves-1"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* user -> User{} = a user object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-1"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { fetchUser } from '@kineticdata/react';

fetchUser({ username }).then(({ user }) => console.log(user));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "updateuser"
    }}>{`updateUser`}</h2>
    <h3 {...{
      "id": "params-2"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* username -> string = (required) username of the user
* user -> User{} = (required) the updated user data to send
`}</code></pre>
    <h3 {...{
      "id": "resolves-2"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* user -> User{} = the updated user object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-2"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { updateUser } from '@kineticdata/react';

updateUser({
  username,
  user: userWithChanges,
}).then(({ user }) => console.log(user));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "createuser"
    }}>{`createUser`}</h2>
    <h3 {...{
      "id": "params-3"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* user -> User{} = (required) the user data to send
`}</code></pre>
    <h3 {...{
      "id": "resolves-3"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* user -> User{} = the created user object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-3"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { createUser } from '@kineticdata/react';

createUser({ user }).then(({ user }) => console.log(user));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "deleteuser"
    }}>{`deleteUser`}</h2>
    <h3 {...{
      "id": "params-4"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* username -> string = (required) username of the user
`}</code></pre>
    <h3 {...{
      "id": "resolves-4"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* user -> User{} = the deleted user object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-4"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { deleteUser } from '@kineticdata/react';

deleteUser({ username }).then(({ user }) => console.log(user));
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      